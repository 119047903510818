






























































import { Notify, Toast } from "vant";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import Stroage from "../../util/Storage";
import { Vue, Component, Prop } from "vue-property-decorator";
import { UserType } from "../../Api/Basics/InterfaceType";
import { WXUserType } from "../../Type/index";
import { SetLogin, SetPassWord,GetPhoneCode, SetUserData } from "../../Api/Basics/index";
import ZoomPage from "@/impView/PageSafety";
interface SetPassType<T = string> {
  userId?: T;
  token?: T;
  title:T;
  phone: T;
  code: T;
  newPass?: T;
  num: number;
  CodeText: T;
  PassBool: boolean;
  loadingShow: boolean;
  userData?: UserType<string>;
  WXData?: WXUserType;

  initData(): void;
  handleGetCode(): void;
  handleUpGetCode():void;
  handleClickBtn(): void;
  handleSetPassWord(): void;
  handleLogin(): void;
  handleError(data: string): void;
}

@Component({ name: "SetPass",components:{ HeadTop } })
export default class SetPass extends ZoomPage implements SetPassType {
  userId?: string = "";
  token?: string = "";
  title = "设置支付密码";
  phone = "";
  code = "";
  newPass? = "";
  num = -1;
  CodeText = "获取验证码";
  PassBool = true;
  loadingShow = true;
  userData: UserType<string> = {};
  WXData: WXUserType<string> = {};
  mounted() {
    this.loadingShow = false;
    this.initData();
  }

  initData() {
    let reg = /true/g;
    try{
      this.userData = Stroage.GetData_ && JSON.parse(Stroage.GetData_("UserData"));
      if (this.$route.query.PassBool && reg.test(String(this.$route.query.PassBool))) {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId");
        this.token = Stroage.GetData_ && Stroage.GetData_("token");
        this.phone = this.userData.phone as string;
        this.PassBool = true;
        this.title = "设置支付密码"
      } else {
        this.WXData = Stroage.GetData_ && JSON.parse(Stroage.GetData_("WXUserInfo"));
        this.PassBool = false
        this.title = "绑定手机号"
      }
    }catch(cat){ }
  }

  @Prop([String, Boolean])
  State!: string | boolean;

  handleGetCode() {
    let { phone } = this;
    let reg = / /g;
    phone = phone.replace(reg, "");
    if( !(phone.length == 11) ){
        this.handleError("请输入手机号不正确");
        return;
    }
    this.num = 60;
    let time = setInterval(() => {
        this.num--;
      if (this.num < 0) {
          clearTimeout(time);
        this.CodeText = "重新获取";
      }
    }, 1000);
    // 获取验证码
    this.handleUpGetCode()
  }

  handleUpGetCode(){
      let { phone,PassBool } = this;
      GetPhoneCode({
          phone,
          templateCode:PassBool ? 3 : 0
      }).then(res=>res)
  }

  handleClickBtn() {
    if (this.PassBool) {
      this.handleSetPassWord();
    } else {
      this.handleLogin();
    }
  }

  handleSetPassWord() {
    let { phone, code, newPass } = this;
    let reg = / /g;
    phone = phone.replace(reg, "");
    code = code.replace(reg, "");
    newPass = newPass && newPass.replace(reg, "");
    if (!(code.length > 4)) {
      this.handleError("请输入验证码");
      return;
    }
    if (!(newPass?.length! > 5)) {
      this.handleError("请输入支付密码");
      return;
    }
    this.loadingShow = true;
    SetPassWord(
      {
        userId: this.userId! && this.userId as string,
        token: this.token! && this.token as string,
      },
      {
        phone,
        smsCode: code,
        payPassword: newPass! && newPass as string,
      }
    ).then((res: any) => {
      this.loadingShow = false;
      if (res.message.code === "200") {
        Toast("设置成功");
        let time = setTimeout(() => {
          this.$router.go(-1);
          clearTimeout(time);
        }, 1000);
      } else {
        this.handleError(res.message.msg);
      }
    });
  }

  handleLogin() {
    let { phone, code } = this;
    let reg = / /g;
    phone = phone.replace(reg, "");
    code = code.replace(reg, "");
    if (!(phone.length == 11)) {
      this.handleError("请输入手机号");
      return;
    }
    if (!(code.length > 4)) {
      this.handleError("请输入验证码");
      return;
    }
     let { nick, headimgurl, sex, province, city, openid } = this.WXData;
    /*let UpData = {
      name: nick,
      nickName: nick,
      headImg: headimgurl,
      sex: sex,
      address: `${province} ${city}`,
      openId: openid,
    }; */
    this.loadingShow = true;
    new Promise((resolve, reject) => {
      SetLogin({
        phone,
        smsCode: code,
        openId: openid as string,
      }).then((res: any) => {
        if (res.message.code === "200") {
          Stroage.SetData_ && Stroage.SetData_("userId", res.data.userId);
          Stroage.SetData_ && Stroage.SetData_("token", res.data.token);
          resolve(res.data);
        } else {
          reject(res);
        }
      });
    }).then( res=>{
      this.loadingShow = false
      this.$router.replace({
        path:"/vTMessage"
      })
    } )
     /*  .then((res: any) => {
        SetUserData(
          {
            userId: res.userId as string,
            token: res.token as string,
          },
          UpData
        ).then((res: any) => {
          this.loadingShow = false;
          if (res.message.code === "200") {
            Toast("请绑定所在学校");
            let time = setTimeout(() => {
              this.$router.replace({
                path: "/school",
              });
              clearTimeout(time);
            }, 1000);
          } else {
            this.handleError(res.message.msg);
          }
        });
      }) */
      .catch((cat: any) => {
        this.handleError(cat.message.msg);
        this.loadingShow = false;
      });
  }

  handleError(data: string) {
    let str = "";
    if (data.length) {
      str = data;
    } else {
      str = "网络繁忙...";
    }
    Notify({
      message: str,
      color: "white",
      background: "rgba(0,0,0,.6)",
    });
  }
}
